<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-3 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-baseline">
              <span class="mb-0">Balance</span>
            </div>
            <div class="row">
              <div class="col-12 col-md-12 col-xl-12">
                <h6 class="mb-2 font-weight-bold">Rp. {{ workspaceBalance }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 grid-margin stretch-card" v-for="(asset, index) in assets.filter((a) => workspaceFreeQuota[a])" :key="index">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-baseline">
              <span class="mb-0" v-if="!['free_credit'].includes(asset)">Free {{ asset_name[asset] }} Quota</span>
              <span class="mb-0" v-else>{{ asset_name[asset] }}</span>
            </div>
            <div class="row">
              <div class="col-12 col-md-12 col-xl-12">
                <h6 class="mb-2 font-weight-bold" v-if="!['free_credit'].includes(asset)">{{ workspaceFreeQuota[asset] }} quota(s)</h6>
                <h6 class="mb-2 font-weight-bold" v-else>Rp. {{ workspaceFreeQuota[asset].toLocaleString('id') }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <el-date-picker
                v-model="search.date_range"
                type="daterange"
                size="small"
                :picker-options="date_picker_options"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker> &nbsp;
              <!-- <el-select v-model="dateFilterType" size="small">
                <el-option v-for="(item, i) in dateFilterTypeOptions" :key="i" :value="item.value" :label="item.text" />
              </el-select> &nbsp; -->
              <el-button :loading="loading.table" @click="handlerSearchBtn()" size="small" class="btn-primary" icon="el-icon-search">Search</el-button>
              <el-button :loading="loading.table" @click="downloadReport();" size="small" class="btn-secondary" icon="el-icon-download">Download</el-button>
            </div>
            <hr/>
            <div class="table-responsive">
              <table class="table table-hover" v-loading="loading.table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Source Of Fund</th>
                    <th>Ref. Number</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in rows" :key="i">
                    <td>{{ item.date_str }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.type == 'debit' ? item.amount_str : '-' }}</td>
                    <td>{{ item.type == 'credit' ? item.amount_str : '-' }}</td>
                    <td>{{ item.sof_str }}</td>
                    <td>{{ item.reference_number || item.txid }}</td>
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="!rows.length" description="No histories"></el-empty>
            </div>
            <b-pagination v-if="count > search.per_page" v-model="currentPage" :total-rows="count" :per-page="search.per_page"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { isEqual } from 'lodash';
import workspaceApi from '../../../api/workspaces';
import topupApi from '../../../api/topup';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  metaInfo: {
    title: 'Deposit Histories',
  },
  data() {
    return {
      loading: {
        table: false,
      },
      search: {
        date_range: [
          this.$route.query?.start_date || moment().subtract(7, 'days'),
          this.$route.query?.end_date || moment(),
        ],
        page: this.$route.query?.page || 1,
        per_page: this.$route.query?.per_page || 10,
        next_page: this.$route.query?.next_page || null,
        previous_page: this.$route.query?.previous_page || null,
      },
      rows: [],
      count: 0,
      sof: {
        balance: 'Balance',
        free_quota: 'Free WhatsApp Quota',
        free_quota_bi: 'Free WhatsApp BI',
        free_quota_ui: 'Free WhatsApp UI',
        free_quota_mkt: 'Free WhatsApp Marketing',
        free_quota_auth: 'Free WhatsApp Authentication',
        free_quota_svc: 'Free WhatsApp Service',
        free_quota_util: 'Free WhatsApp Utility',
      },
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const start = moment().subtract(3, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      assets: [
        'whatsapp',
        'whatsapp_bi',
        'whatsapp_ui',
        'whatsapp_mkt',
        'whatsapp_auth',
        'whatsapp_svc',
        'whatsapp_util',
        'free_credit',
      ],
      asset_name: {
        whatsapp: 'WhatsApp',
        whatsapp_bi: 'Business Initiated',
        whatsapp_ui: 'User Initiated',
        whatsapp_mkt: 'Marketing',
        whatsapp_auth: 'Authentication',
        whatsapp_svc: 'Service',
        whatsapp_util: 'Utility',
        free_credit: 'Free Credit',
      },
    };
  },
  computed: {
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace || {};
    },
    workspaceBalance() {
      return this.$store.state.workspace.workspaceBalance?.toLocaleString('id-ID');
    },
    workspaceFreeQuota() {
      return this.$store.state.workspace.workspaceFreeQuota;
    },
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getList();
      },
    },
  },
  mounted() {
    this.getList();
    this.checkWorkspaceBalance();
  },
  methods: {
    async checkWorkspaceBalance() {
      if (this.activeWorkspace._id) {
        const response = await workspaceApi.check_balance({
          id: this.activeWorkspace._id,
        });
        await popupErrorMessages(response);
        this.$store.dispatch('workspace/setWorkspaceBalance', response.data);
      }
    },
    /* eslint-disable radix */
    handlerNextPrev(value) {
      if (value === 'next') {
        this.search.page = parseInt(this.search.page) + 1;
        this.search.previous_page = null;
        if (!this.search.next_page) {
          return;
        }
      } else {
        this.search.page = parseInt(this.search.page) - 1;
        this.search.next_page = null;
        if (!this.search.previous_page) {
          return;
        }
      }
      this.getList();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async getList(action = '') {
      const options = {
        page: this.search.page.toString(),
        per_page: this.search.per_page.toString(),
        start_date: moment(this.search.date_range[0]).format('YYYY-MM-DD'),
        end_date: moment(this.search.date_range[1]).format('YYYY-MM-DD'),
        next_page: this.search.next_page,
        previous_page: this.search.previous_page,
      };

      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      this.loading.table = true;
      if (action === 'download') options.action = 'download';
      await topupApi.depositHistories({
        workspace_id: this.activeWorkspace._id,
        query: options,
      }).then(({ data }) => {
        if (options.action === 'download') {
          this.$message({
            title: this.$t('general.success'),
            type: 'success',
            message: this.$t('general.waiting_download'),
            duration: 30 * 1000,
            showClose: true,
          });
          return;
        }
        this.search.next_page = data.next_page;
        this.search.previous_page = data.previous_page;
        this.count = data.count;
        this.rows = data.rows.map((v) => {
          v.date_str = moment(v.date).format('DD MMM YYYY, HH:mm:ss');
          v.sof_str = this.sof[v.source_of_fund] || v.source_of_fund;
          if (['free credit', 'balance'].includes(v.source_of_fund.toLowerCase())) {
            v.amount_str = `Rp. ${v.amount.toLocaleString('id-ID')}`;
            // v.balance_after_str = `Rp. ${v.balance_after.toLocaleString()}`;
          } else {
            v.amount_str = `${v.amount.toLocaleString()} quota`;
            // v.balance_after_str = `${v.balance_after.toLocaleString()} quota`;
          }
          return v;
        });
      });
      this.loading.table = false;
    },
    handlerSearchBtn() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.search.next_page = null;
      this.search.previous_page = null;
      this.search.page = 1;
      this.getList();
    },
    async downloadReport() {
      this.getList('download');
    },
  },
};
</script>
